import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { ApiResponse } from '../../models/ApiResponse';
import { ClientFormDefault, FormDefault, FormSectionConfiguration } from '../../models/ClientFormDefaults';
import { ClientFormUser } from '../../models/ClientFormUser';
import { ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import { DateInterval, DateIntervalKeys } from '../../models/DateInterval';
import { FormConfig, PeriodicReviewConfig } from '../../models/Form';
import { FormType } from '../../models/FormTypes';
import User from '../../models/User';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import ClientService from '../../services/ClientService';
import ClientTemplateFormService from '../../services/ClientTemplateFormService';
import LanguageUtils from '../../utils/LanguageUtils';
import FormOwnership from '../ownership/FormOwnership';
import { OwnershipDisplayType } from '../ownership/OwnershipProps';
import CollapsableDataRow from '../shared/data-grid/CollapsableDataRow';
import SortableHeading from '../shared/data-grid/SortableHeading';
import CheckIcon from '../shared/icon/CheckIcon';
import ActivityIcon from '../shared/icon/ActivityIcon';
import InfoIcon from '../shared/icon/InfoIcon';
import Loader from '../shared/Loader';
import { Heading, HeadingSize } from '../shared/text/Heading';
import DateIntervalEditor from './DateIntervalEditor';
import FormSectionDefaults from './OrgFormSectionDefaults';
import { ModalContext } from '../../contexts/ModalContext';
import ReviewWizard, { EmptyPeriodicReviewConfig, PeriodicReviewMode } from '../shared/periodic-review/ReviewWizard';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import { PeriodicReviewUtils } from '../../utils/PeriodicReviewUtils';
import StandardModal from '../shared/modal/variants/StandardModal';
import PeriodicReviewRecurrence from '../shared/periodic-review/PeriodicReviewRecurrence';
import PdfPreferences from '../form/DownloadPdfPreferences';
import { DefaultDownloadPdfPreferences } from '../../models/DownloadPdfPreferences';

type ClientFormTemplateListProps = {
  clientFormDefaultsPaged: ApiResponse<ClientFormDefault[]>;
  onLoadMore?: (pageNumber: number) => void;
  sortBy: string;
  onSort?: (expression: string) => void;
  isLoading: boolean;
  onUpdated: (clientFormDefault: ClientFormDefault) => void;
};

const FormDefaultsList: FC<ClientFormTemplateListProps> = (props) => {
  const { clientFormDefaultsPaged: formsPaged, onLoadMore, onSort, onUpdated, sortBy, isLoading } = props;
  const [sortExpressions, setSortExpressions] = useState<Record<string, string>>({});
  const [selectedClientFormDefault, setSelectedClientFormDefault] = useState<ClientFormDefault | null>(null);
  const [openDateEditor, setOpenDateEditor] = useState(false);
  const [showPeriodicReviewWizard, setShowPeriodicReviewWizard] = useState(false);
  const [showMoreSettings, setShowMoreSettings] = useState(false);
  const [showRemoveReviewModal, setShowRemoveReviewModal] = useState(false);
  const [rowState, setRowState] = useState<Record<string, boolean>>({});
  const [clientUsers, setClientUsers] = useState<User[]>([]);
  const currentPage = formsPaged.pageNumber || 0;
  const totalPages = formsPaged.totalPages || 0;
  const currentClient = useRecoilValue(currentClientAtom);
  const toaster = useToasts();

  const { t } = useTranslation(['organisation', 'common', 'periodic-review', 'form-builder']);

  const updateSortExpression = (field: string, expression: string) => {
    const expressions = { ...sortExpressions, [field]: expression };
    setSortExpressions(expressions);

    const fullExpression = Object.values(expressions).filter((x) => x !== expression);
    fullExpression.unshift(expression); // move to first

    onSort && onSort(fullExpression.join(','));
  };

  useEffect(() => {
    let expressions = {};
    for (const expression of sortBy.split(',')) {
      expressions = { ...expressions, [expression.replace(/[-+]/g, '')]: expression };
    }
    setSortExpressions(expressions);
  }, [sortBy]);

  const [lastElementRef] = useInfiniteScroll(currentPage < totalPages ? () => onLoadMore && onLoadMore(currentPage + 1) : null, isLoading);

  const selectFormDefault = (clientFormDefault: ClientFormDefault) => {
    setSelectedClientFormDefault(clientFormDefault);
  };

  useEffect(() => {
    if (currentClient) {
      ClientService.getUsers().then((res) => {
        setClientUsers(res);
      });
    }
  }, [currentClient]);

  const onUsersChange = (users: ClientFormUser[], clientFormDefault: ClientFormDefault) => {
    const formDefaults = {
      ...clientFormDefault.defaults,
      configuration: {
        ...clientFormDefault.defaults?.configuration,
        users: Object.assign({}, ...users.map((x) => ({ [x.id || '']: x.role }))),
      },
    } as FormDefault;

    createOrUpdate(formDefaults, clientFormDefault.templateForm);
  };

  const patchSections = (section: FormSectionConfiguration, clientFormDefault: ClientFormDefault) => {
    const sections = clientFormDefault.defaults?.configuration?.sections || [];
    const hasSection = sections.find((x) => x.id === section.id);
    const newSections = hasSection
      ? sections.map((x) => {
          if (x.id === section.id) {
            return { ...x, ...section };
          }
          return x;
        })
      : [...sections, section];
    const formDefaults = {
      ...clientFormDefault.defaults,
      configuration: {
        ...clientFormDefault.defaults?.configuration,
        sections: newSections,
      },
    } as FormDefault;
    return formDefaults;
  };

  const onSectionUsersChange = (section: FormSectionConfiguration, clientFormDefault: ClientFormDefault) => {
    const formDefaults = patchSections(section, clientFormDefault);
    createOrUpdate(formDefaults, clientFormDefault.templateForm);
  };

  const createOrUpdate = useCallback(
    (formDefault: FormDefault, templateForm: Partial<FormConfig>) => {
      if (currentClient) {
        return new ClientTemplateFormService(currentClient.id).createFormDefaults(templateForm.id || '', formDefault.configuration).then((res) => {
          onUpdated({ templateForm, defaults: res.data });
        });
      } else {
        return Promise.reject();
      }
    },
    [currentClient, onUpdated],
  );

  const setDueDate = (amount: number | null, interval: DateInterval | null) => {
    if (!selectedClientFormDefault) {
      return;
    }

    const defaults = {
      ...selectedClientFormDefault.defaults,
      configuration: {
        ...selectedClientFormDefault.defaults?.configuration,
        dueDateInterval: interval,
        dueDateIntervalAmount: amount,
      },
    } as FormDefault;

    setOpenDateEditor(false);
    setSelectedClientFormDefault(null);
    setRowState({});
    createOrUpdate(defaults, selectedClientFormDefault.templateForm);
  };

  const onCreatorRoleChange = (role: ClientFormUserRoleValues | null, clientFormDefault: ClientFormDefault) => {
    const defaults = {
      ...clientFormDefault.defaults,
      configuration: {
        ...clientFormDefault.defaults?.configuration,
        creatorRole: role,
      },
    } as FormDefault;

    onUpdated({ templateForm: clientFormDefault.templateForm, defaults: defaults });
  };

  const onSectionCreatorRoleChange = (section: FormSectionConfiguration, clientFormDefault: ClientFormDefault) => {
    const defaults = patchSections(section, clientFormDefault);
    onUpdated({ templateForm: clientFormDefault.templateForm, defaults: defaults });
  };

  const [periodicReviewErrors, setPeriodicReviewErrors] = useState<string[]>([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const onPeriodicReviewChange = (periodicReviewConfig: PeriodicReviewConfig | null) => {
    if (!selectedClientFormDefault) {
      return;
    }
    const periodicValidationErrors = PeriodicReviewUtils.validate(periodicReviewConfig, t);
    if (periodicValidationErrors.length > 0) {
      setShowErrorModal(true);
      setPeriodicReviewErrors(periodicValidationErrors);
      return;
    }

    const defaults = {
      ...selectedClientFormDefault.defaults,
      configuration: {
        ...selectedClientFormDefault.defaults?.configuration,
        periodicReviewConfig: periodicReviewConfig,
      },
    } as FormDefault;

    setShowPeriodicReviewWizard(false);
    setShowRemoveReviewModal(false);
    setSelectedClientFormDefault(null);
    setRowState({});
    createOrUpdate(defaults, selectedClientFormDefault.templateForm).then(() => {
      toaster.addToast({
        title: t('organisation:forms-defaults.list.tosters.review-saved.title'),
        description: t('organisation:forms-defaults.list.tosters.review-saved.description', {
          'template-title': LanguageUtils.getTranslation('title', selectedClientFormDefault.templateForm.translations || {}),
        }),
        type: ToastType.SUCCESS,
        expiresInMs: 3000,
      });
    });
  };

  const onDownloadPdfPreferencesChange = useCallback((key: string, value: boolean) => {
    setSelectedClientFormDefault((prev) => {
      if (!prev) {
        return prev;
      }
      return {
        ...prev,
        defaults: {
          ...(prev.defaults || { id: '' }),
          configuration: {
            ...(prev.defaults?.configuration || {}),
            downloadPdfPreferences: {
              ...(prev.defaults?.configuration.downloadPdfPreferences || prev.templateForm.downloadPdfPreferences || DefaultDownloadPdfPreferences),
              [key]: value,
            },
          },
        },
      };
    });
  }, []);

  const saveDefaults = useCallback(
    (callback?: () => void) => {
      if (selectedClientFormDefault && selectedClientFormDefault.defaults) {
        createOrUpdate(selectedClientFormDefault.defaults, selectedClientFormDefault.templateForm)
          .then(() => {
            toaster.addToast({
              title: t('organisation:forms-defaults.list.tosters.settings-saved.title'),
              description: t('organisation:forms-defaults.list.tosters.settings-saved.description', {
                'template-title': LanguageUtils.getTranslation('title', selectedClientFormDefault.templateForm.translations || {}),
              }),
              type: ToastType.SUCCESS,
              expiresInMs: 3000,
            });
            onUpdated(selectedClientFormDefault);
          })
          .finally(() => {
            callback && callback();
          });
      }
    },
    [createOrUpdate, onUpdated, selectedClientFormDefault, t, toaster],
  );

  const getUsers = useCallback(
    (defaults?: FormDefault): ClientFormUser[] => {
      if (defaults && clientUsers.length) {
        const users = Object.entries(defaults?.configuration?.users || {}).map(([key, value]) => {
          const clientUser = clientUsers.find((user) => user.id && user.id === key);
          return { ...(clientUser as User), role: value };
        });
        return users;
      }
      return [];
    },
    [clientUsers],
  );

  const getPeriodicReviewMode = useCallback((formDefault: ClientFormDefault): PeriodicReviewMode => {
    if (formDefault.defaults?.configuration.periodicReviewConfig) {
      return 'manage';
    }
    if (formDefault.templateForm.periodicReviewConfig) {
      return 'override';
    }
    return 'set';
  }, []);

  const getPeriodicReviewMenu = useCallback(
    (formDefault: ClientFormDefault) => {
      const mode = getPeriodicReviewMode(formDefault);
      switch (mode) {
        case 'set':
          return t('organisation:forms-defaults.list.actions.set-review');
        case 'manage':
          return t('organisation:forms-defaults.list.actions.manage-review');
        case 'override':
          return t('organisation:forms-defaults.list.actions.override-review');
      }
    },
    [getPeriodicReviewMode, t],
  );

  return (
    <div>
      <div className="flex w-full items-end border-2 border-transparent pl-4">
        <div className="w-full">
          <div className="w-12">{/* SPACER */}</div>
          <SortableHeading title="Name" onSort={updateSortExpression} expression={sortExpressions['title'] ?? '+title'} />
        </div>
        <div className="w-64 text-center">{t('organisation:forms-defaults.list.due')}</div>
        <div className="w-64 text-center">{t('organisation:forms-defaults.list.users')}</div>
        <div className="w-64 text-center">{t('organisation:forms-defaults.list.review')}</div>
        <div className="w-24">{/* SPACER */}</div>
        <div className="w-16">{/* SPACER */}</div>
      </div>
      {formsPaged.data.map((form, i) => {
        const isLast = formsPaged.data.length === i + 1;
        return (
          <div key={form.templateForm.id} ref={isLast ? lastElementRef : undefined}>
            <CollapsableDataRow
              data-cy={`form-${form.templateForm.id}`}
              key={form.templateForm.id}
              onClick={() => {
                selectFormDefault(form);
                const formId = form.templateForm.id || '';
                setRowState((prevState) => ({
                  [formId]: !prevState[formId],
                }));
              }}
              open={rowState[form.templateForm.id || '']}
              contextMenuItems={[
                {
                  title: t('organisation:forms-defaults.list.actions.edit-due'),
                  onClick: () => {
                    selectFormDefault(form);
                    setOpenDateEditor(true);
                  },
                },
                {
                  title: getPeriodicReviewMenu(form),
                  onClick: () => {
                    selectFormDefault(form);
                    setShowPeriodicReviewWizard(true);
                  },
                },
                {
                  title: t('organisation:forms-defaults.list.actions.download-settings'),
                  onClick: () => {
                    selectFormDefault(form);
                    setShowMoreSettings(true);
                  },
                  hide: form.templateForm.type === FormType.Document,
                },
              ]}
            >
              <CollapsableDataRow.Header>
                <div className="flex w-full items-center text-left">
                  {form.templateForm.type === FormType.Asset && (
                    <ActivityIcon className="bg-gray-1 mr-4 h-8 w-8 flex-shrink-0 rounded-full p-2 text-white" />
                  )}
                  {form.templateForm.type !== FormType.Asset && (
                    <CheckIcon className="bg-gray-1 mr-4 h-8 w-8 flex-shrink-0 rounded-full p-2 text-white" />
                  )}
                  <div>
                    <div className="text-color-2 font-medium">{LanguageUtils.getTranslation('title', form.templateForm.translations || {})}</div>
                    <div className="text-color-3 text-dpm-12">{form.templateForm.code}</div>
                  </div>
                </div>

                <div className="w-64 text-center">
                  {form?.defaults?.configuration?.dueDateIntervalAmount
                    ? `${form?.defaults?.configuration?.dueDateIntervalAmount} ${t(
                        DateIntervalKeys[form?.defaults?.configuration?.dueDateInterval || DateInterval.DAY],
                      )}`
                    : '-'}
                </div>
                <div className="flex w-64 flex-col items-center">
                  <FormOwnership
                    users={getUsers(form.defaults)}
                    size={OwnershipDisplayType.Tiny}
                    onUsersChange={(users: ClientFormUser[]) => {
                      onUsersChange(users, form);
                    }}
                    modalHeading={t('organisation:forms-defaults.default-users-heading')}
                    requiresApproval={!!form.templateForm.requiresApproval}
                    requiresValidation={!!form.templateForm.requiresValidation}
                    creatorRole={form.defaults?.configuration.creatorRole}
                    onCreatorRoleChange={(role: ClientFormUserRoleValues | null) => {
                      onCreatorRoleChange(role, form);
                    }}
                    shouldValidateRoles={false}
                  />
                </div>
                <div className="flex w-64 flex-col items-center text-center">
                  <PeriodicReviewRecurrence source={form} />
                </div>
              </CollapsableDataRow.Header>
              <CollapsableDataRow.Content>
                <div className="flex flex-wrap gap-6">
                  {selectedClientFormDefault &&
                    form?.templateForm?.sections?.map((section) => {
                      return (
                        <FormSectionDefaults
                          key={section.id}
                          clientUsers={clientUsers}
                          section={section}
                          configuration={form.defaults?.configuration.sections?.find((x) => x.id === section.id) || null}
                          onUsersChange={(configuration: FormSectionConfiguration) => {
                            onSectionUsersChange(configuration, form);
                          }}
                          onCreatorRoleChange={(configuration: FormSectionConfiguration) => {
                            onSectionCreatorRoleChange(configuration, form);
                          }}
                          requiresApproval={!!section.requiresApproval}
                          requiresValidation={false}
                        />
                      );
                    })}
                </div>
              </CollapsableDataRow.Content>
            </CollapsableDataRow>
          </div>
        );
      })}
      {selectedClientFormDefault && (
        <>
          <DateIntervalEditor
            title={t('organisation:forms-defaults.due-date-heading')}
            open={openDateEditor}
            amount={selectedClientFormDefault?.defaults?.configuration?.dueDateIntervalAmount || 0}
            interval={selectedClientFormDefault?.defaults?.configuration?.dueDateInterval || DateInterval.DAY}
            onClose={() => setOpenDateEditor(false)}
            onSave={(amount, interval) => setDueDate(amount, interval)}
          />
          <ModalContext.Provider
            value={{
              open: showPeriodicReviewWizard,
              onClose: () => {
                setShowPeriodicReviewWizard(false);
                setSelectedClientFormDefault(null);
                setRowState({});
              },
              modalWidth: 'lg:w-2/5 w-1/2',
            }}
          >
            <ReviewWizard
              onDelete={getPeriodicReviewMode(selectedClientFormDefault) === 'manage' ? () => setShowRemoveReviewModal(true) : undefined}
              state={getPeriodicReviewMode(selectedClientFormDefault)}
              onSave={onPeriodicReviewChange}
              onCancel={() => {
                setShowPeriodicReviewWizard(false);
                setSelectedClientFormDefault(null);
                setRowState({});
              }}
              config={
                selectedClientFormDefault.defaults?.configuration.periodicReviewConfig ||
                selectedClientFormDefault.templateForm.periodicReviewConfig ||
                EmptyPeriodicReviewConfig
              }
            />
          </ModalContext.Provider>
          <ModalContext.Provider
            value={{
              open: showMoreSettings,
              onClose: () => {
                setShowMoreSettings(false);
                setSelectedClientFormDefault(null);
                setRowState({});
              },
              modalWidth: 'lg:w-2/5 w-1/2',
            }}
          >
            <StandardModal
              onConfirmClick={() =>
                saveDefaults(() => {
                  setShowMoreSettings(false);
                  setSelectedClientFormDefault(null);
                  setRowState({});
                })
              }
              confirmButtonTitle={t('common:buttons.save')}
              title={t('form-builder:form-properties.download-pref')}
              subTitle={t('form-builder:form-properties.download-pref-description')}
            >
              <PdfPreferences
                preferences={
                  selectedClientFormDefault.defaults?.configuration.downloadPdfPreferences ||
                  selectedClientFormDefault.templateForm.downloadPdfPreferences
                }
                onPreferenceChange={onDownloadPdfPreferencesChange}
              />
            </StandardModal>
          </ModalContext.Provider>
          <ModalContext.Provider
            value={{ open: showRemoveReviewModal, modalWidth: 'w-2/5', onClose: () => setShowRemoveReviewModal(false), priorityOverride: 10 }}
          >
            <ConfirmationModal
              onConfirm={() => onPeriodicReviewChange(null)}
              onCancel={() => setShowRemoveReviewModal(false)}
              title={t('periodic-review:modals.delete.title')}
              description={t('periodic-review:modals.delete.description')}
              confirmText={t('periodic-review:modals.delete.buttons.confirm')}
            />
          </ModalContext.Provider>

          <ModalContext.Provider value={{ open: showErrorModal, onClose: () => setShowErrorModal(false), modalWidth: 'w-2/6', priorityOverride: 10 }}>
            <StandardModal
              onConfirmClick={() => setShowErrorModal(false)}
              confirmButtonTitle={t('periodic-review:validation-errors.buttons.close')}
              title={t('periodic-review:validation-errors.title')}
              subTitle={t('periodic-review:validation-errors.description')}
            >
              <ul>
                {periodicReviewErrors.map((err) => (
                  <li key={err}>{err}</li>
                ))}
              </ul>
            </StandardModal>
          </ModalContext.Provider>
        </>
      )}
      {isLoading && (
        <div className="flex flex-col items-center py-6">
          <Loader size={16} centered={false} />
        </div>
      )}
      {formsPaged.data.length === 0 && (
        <div data-cy="task-list-empty" className="flex h-full justify-center">
          <div className="mt-36 text-center">
            <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
            <Heading size={HeadingSize.H3} className="my-4">
              {t('organisation:forms-defaults.list.empty.heading')}
            </Heading>
            <div className="text-dpm-20">{t('organisation:forms-defaults.list.empty.sub-heading')}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormDefaultsList;
